<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>上访信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="上访人姓名">
                <div style="width: 500px;">
                  {{ formData.visitorName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="联系电话">
                <div style="width: 500px;">
                  {{ formData.contactPhone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="区域">
                <div style="width: 500px;">
                  {{ formData.areaCodeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="举报人地址">
                <div style="width: 500px;">
                  {{ formData.visitorAddress }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上访原因">
                <div style="width: 500px;">
                  {{ formData.visitReason }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注">
                <div style="width: 500px;">
                  {{ formData.description }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否已向相关部门反应">
                <div style="width: 500px;" v-if="formData.isReportedBefore > 0">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否已进行处理">
                <div style="width: 500px;" v-if="formData.isSupervisorHandled > 0">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否越级上访">
                <div style="width: 500px;" v-if="formData.isLevelSkiped > 0">
                  是
                </div>
                <div style="width: 500px;" v-else>
                  否
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="陪同人员信息">
                <div style="width: 500px;">
                  {{ formData.accompanyInfo }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="主管村干部/网格员信息">
                <div style="width: 500px;">
                  {{ formData.supervisorInfo }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="预期结果">
                <div style="width: 500px;">
                  {{ formData.expectation }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="录入时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <a-divider>处理信息</a-divider>
      <a-spin :spinning="loadingMissonExec">
        <a-list size="large">
          <a-list-item :key="index" v-for="(item, index) in execList">
            <a-list-item-meta :description="item.description">
              <a slot="title">
                {{ item.creatorName }}({{ item.creatorTelephone }})
              </a>
            </a-list-item-meta>
            <div class="list-content">
              <div class="list-content-item">
                <span style="min-width: 90px;text-align: left;">处理时间</span>
                <p style="min-width: 90px;text-align: left;">
                  {{ item.createTime }}
                </p>
              </div>
            </div>
            <div class="list-content" style="margin-left: 20px;">
              <div class="list-content-item">
                <span style="min-width: 60px;text-align: left;">操作</span>
                <p style="min-width: 60px;text-align: left;">
                  <a-popconfirm title="确认要删除此记录吗？" @confirm="delDeal(item.keyId)">
                    <a>删除</a>
                  </a-popconfirm>
                </p>
              </div>
            </div>
          </a-list-item>
        </a-list>
      </a-spin>
      <div>
        <p style="cursor: pointer; text-align: center;" v-if="moreData" @click="moreDataFun">点击加载更多...</p>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { dealShangFangRecordList, DelDealShangFangRecord, recordInfo } from '@/api/shangfang'
export default {
  name: 'SeeRecord',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      loadingMissonExec: false,
      execList: [],
      moreData: false,
      execParams: {
        shangFangKeyId: 0,
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    recordInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.execParams.shangFangKeyId = keyId
      _this.execParams.pageNo = 1
      _this.execList = []
      _this.execListApi()
      _this.loading = true
      recordInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    },
    execListApi (isNew = 0) {
      const _this = this
      _this.loadingMissonExec = true
      dealShangFangRecordList(_this.execParams).then((res) => {
        if (res.errorCode === 0) {
          if (isNew > 0) {
            _this.execList = res.result.data;
          } else {
            _this.execList = _this.execList.concat(res.result.data)
          }
          if (res.result.pageNo < res.result.totalPage) {
            _this.moreData = true
          } else {
            _this.moreData = false
          }
        } else {
          _this.$message.error('系统错误')
        }
        _this.loadingMissonExec = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loadingMissonExec = false
      })
    },
    moreDataFun () {
      this.execParams.pageNo += 1
      this.execListApi()
    },
    delDeal (id) {
      console.log(id)
      const _this = this
      DelDealShangFangRecord({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.execParams.pageNo = 1
          _this.execListApi(1)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
