import request from '@/utils/request'
const baseURL = '/baseapi'
const shangfangApi = {
  ShangfangList: '/admin/shangfang/list',
  RecordInfo: '/admin/shangfang/recordInfo',
  RecordById: '/admin/shangfang/recordById',
  SaveRecord: '/admin/shangfang/saveRecord',
  DelRecord: '/admin/shangfang/delRecord',
  AddDealRecord: '/admin/shangfang/addDealRecord',
  DelDealRecord: '/admin/shangfang/delDealRecord',
  DealRecordList: '/admin/shangfang/dealRecordList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function shangfangList (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.ShangfangList,
    method: 'post',
    data: parameter
  })
}

export function recordInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.RecordInfo,
    method: 'post',
    data: parameter
  })
}

export function recordById (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.RecordById,
    method: 'post',
    data: parameter
  })
}

export function saveRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.SaveRecord,
    method: 'post',
    data: parameter
  })
}

export function delRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.DelRecord,
    method: 'post',
    data: parameter
  })
}

export function addDealShangFangRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.AddDealRecord,
    method: 'post',
    data: parameter
  })
}

export function DelDealShangFangRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.DelDealRecord,
    method: 'post',
    data: parameter
  })
}

export function dealShangFangRecordList (parameter) {
  return request({
    baseURL: baseURL,
    url: shangfangApi.DealRecordList,
    method: 'post',
    data: parameter
  })
}
